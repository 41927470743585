import classNames from 'classnames'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useCallback, useContext, useEffect, useState } from 'react'

import * as styles from './MobileNav.module.scss'
import Logo from '../../assets/new-icons/bamboo-logo.svg'
import { MobileMenuSocialLinks, navLinks } from '../../constants/navigation'
import ROUTES from '../../constants/routes'
import { RegionContext } from '../../context/region-context'
import CountryButton from '../country-button/CountryButton'
import CloseButton from '../ui/buttons/custom/close-button/CloseButton'
import GetBambooButton from '../ui/buttons/custom/get-bamboo-button/GetBambooButton'
import Divider from '../ui/divider/Divider'
import { SvgChevron } from '../ui/svg-icons'

function MobileNav({ open, onCloseClick, className, ...rest }) {
  const region = useContext(RegionContext)

  const [isDropdownOpen, setIsDropdownOpen] = useState()

  const toggleDropdown = useCallback(index => {
    setIsDropdownOpen(prev => (prev === index ? undefined : index))
  }, [])

  const nav = navLinks.map(
    (
      { url, us_url, label, disabledOnUS, descriptionTitle, subMenus },
      index
    ) => {
      return region?.isUSRegion && disabledOnUS ? null : (
        <li key={index} className={styles.navigationListItem}>
          {subMenus ? (
            <>
              <div
                className={styles.dropdown}
                onClick={() => {
                  toggleDropdown(index)
                }}
              >
                {label}
                <SvgChevron width={7} height={13} className={styles.arrow} />
              </div>
              <div
                className={classNames(
                  styles.subMenu,
                  isDropdownOpen === index && styles.openSubMenu
                )}
              >
                <ul className={styles.subMenuList}>
                  {subMenus.map(
                    ({ label, url, us_url, disabledOnUS }, index) => {
                      return region?.isUSRegion && disabledOnUS ? null : (
                        <li key={index} className={styles.subMenuItem}>
                          <Link
                            to={region?.isUSRegion && us_url ? us_url : url}
                            title={label}
                            onClick={onCloseClick}
                          >
                            {label}
                          </Link>
                        </li>
                      )
                    }
                  )}
                </ul>
              </div>
            </>
          ) : (
            <Link
              to={region?.isUSRegion && us_url ? us_url : url}
              title={descriptionTitle}
              onClick={onCloseClick}
            >
              {label}
              {subMenus && (
                <SvgChevron width={7} height={13} className={styles.arrow} />
              )}
            </Link>
          )}
        </li>
      )
    }
  )

  const socialLinks = MobileMenuSocialLinks.map(
    ({ title, path, icon: IconComponent }, index) => {
      return (
        <a className={styles.socialLinks} key={`${title}_${index}`} href={path}>
          <IconComponent alt={title} />
        </a>
      )
    }
  )

  useEffect(() => {
    if (open) {
      document.body.classList.add('none-scrollable')
    } else {
      document.body.classList.remove('none-scrollable')
    }
  }, [open])

  return (
    <div
      className={classNames(styles.mobileNav, open && styles.open, className)}
      {...rest}
    >
      <div className={styles.header}>
        <div className={styles.logo}>
          <Link
            to={region.isUSRegion ? ROUTES.US_HOME : ROUTES.HOME}
            title="Bamboo investments App"
          >
            <img
              src={Logo}
              width="32px"
              height="32px"
              alt="Bamboo investments App"
            />
          </Link>
        </div>
        <CloseButton className={styles.close} onClick={onCloseClick} />
      </div>

      <div className={styles.body}>
        <nav className={styles.navigation}>
          <ul className={styles.navigationList}>{nav}</ul>
        </nav>

        <div className={styles.bambooButtonWrapper}>
          <GetBambooButton className={styles.btn} />
        </div>

        <div className={styles.footer}>
          <div className={styles.socialLinks}>{socialLinks}</div>
        </div>

        <Divider />

        <div className={styles.country}>
          <span className={styles.countryText}>
            Country of residence set as{' '}
            <span className={styles.bold}>
              {region.isUSRegion ? 'USA' : 'Australia'}
            </span>
          </span>
          <CountryButton />
        </div>
      </div>
    </div>
  )
}

MobileNav.propTypes = {
  open: PropTypes.bool,
  onCloseClick: PropTypes.func,
  className: PropTypes.string,
}

export default MobileNav
