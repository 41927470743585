import React from 'react'

export function SvgTwitter({ width = 32, height = 33, alt, className }) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      alt={alt}
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.4828 12.0962C24.4958 12.2843 24.4958 12.4723 24.4958 12.6621C24.4958 18.4455 20.0931 25.1155 12.0425 25.1155V25.112C9.6643 25.1155 7.33553 24.4342 5.3335 23.1498C5.6793 23.1914 6.02684 23.2122 6.37525 23.2131C8.34608 23.2148 10.2606 22.5536 11.8111 21.3359C9.93817 21.3003 8.29581 20.0792 7.72207 18.2964C8.37814 18.423 9.05415 18.397 9.6981 18.221C7.6562 17.8085 6.18718 16.0145 6.18718 13.931C6.18718 13.9119 6.18718 13.8937 6.18718 13.8755C6.79559 14.2144 7.4768 14.4024 8.17361 14.4232C6.25044 13.1379 5.65763 10.5795 6.81899 8.5792C9.04115 11.3136 12.3198 12.9759 15.8394 13.1518C15.4867 11.6316 15.9685 10.0387 17.1056 8.97007C18.8684 7.31298 21.6409 7.39792 23.298 9.15988C24.2783 8.96661 25.2177 8.60694 26.0775 8.09733C25.7507 9.11048 25.0669 9.97109 24.1535 10.518C25.021 10.4157 25.8686 10.1834 26.6668 9.82895C26.0792 10.7095 25.3391 11.4765 24.4828 12.0962Z"
        fill="#0047BB"
      />
    </svg>
  )
}
