import classNames from 'classnames'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'

import * as styles from './Menu.module.scss'
import { navLinks } from '../../constants/navigation'
import { colors } from '../../constants/style'
import { RegionContext } from '../../context/region-context'

function Menu({ className }) {
  const region = useContext(RegionContext)

  const activeStyle = {
    color: colors.blueA700,
    backgroundColor: colors.blue400,
  }

  const linksElement = navLinks.map(
    ({ url, us_url, label, disabledOnUS, descriptionTitle }, index) => {
      return region?.isUSRegion && disabledOnUS ? null : (
        <li className={styles.menuItem} key={`${label}_${index}`}>
          <Link
            to={region?.isUSRegion && us_url ? us_url : url}
            activeStyle={activeStyle}
            title={descriptionTitle}
            className={styles.link}
          >
            {label}
          </Link>
        </li>
      )
    }
  )

  return (
    <nav className={classNames(styles.menuWrapper, className)}>
      <ul className={styles.menu}>{linksElement}</ul>
    </nav>
  )
}

Menu.propTypes = {
  className: PropTypes.string,
}

export default Menu
