import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import * as styles from './Divider.module.scss'

function Divider({ className }) {
  return (
    <div className={classNames(styles.divider, className)}>
      <hr />
    </div>
  )
}

Divider.propTypes = {
  className: PropTypes.string,
}

export default Divider
