import React from 'react'

export function SvgChevronDown({
  width = 10,
  height = 6,
  className,
  fill = '#001781',
}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.22917 0.499964L4.99583 3.7333L1.7625 0.499964C1.60681 0.34392 1.39543 0.256226 1.175 0.256226C0.954569 0.256226 0.743193 0.34392 0.5875 0.499964C0.2625 0.824964 0.2625 1.34996 0.5875 1.67496L4.4125 5.49996C4.7375 5.82496 5.2625 5.82496 5.5875 5.49996L9.4125 1.67496C9.7375 1.34996 9.7375 0.824964 9.4125 0.499964C9.0875 0.183297 8.55417 0.174964 8.22917 0.499964Z"
        fill={fill}
      />
    </svg>
  )
}
