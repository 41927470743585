import React from 'react'

export function SvgPlay({ width = 24, height = 24, className }) {
  return (
    <svg
      {...{ width, height, className }}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8 5v14l11-7L8 5Z" fill="#0047BB" />
    </svg>
  )
}
