import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import * as styles from './Input.module.scss'

function Input({ className, small, icon, ...rest }) {
  return (
    <div className={classNames(styles.wrapper)}>
      <input
        className={classNames(styles.input, small && styles.small, className, {
          [styles.hasIcon]: icon,
        })}
        {...rest}
      />
      {icon && <span className={styles.iconWrapper}>{icon}</span>}
    </div>
  )
}

Input.propTypes = {
  className: PropTypes.string,
  small: PropTypes.bool,
  icon: PropTypes.node,
}

export default Input
