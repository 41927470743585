import React from 'react'

import { colors } from '../../../constants/style'

export function SvgSearch({
  width = 24,
  height = 24,
  color = colors.grey200,
  className,
}) {
  return (
    <svg
      {...{ width, height, className }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12 12"
    >
      <circle cx="4.5" cy="4.5" r="4" fill="none" stroke={color} />
      <path stroke={color} d="M11 11L7.5 7.5" />
    </svg>
  )
}
