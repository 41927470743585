import { Link } from 'gatsby'
import React, { useContext } from 'react'
import CookieConsent from 'react-cookie-consent'

import * as styles from './Cookie.module.scss'
import { USA_COUNTRY_CODE } from '../../constants/geolocation'
import ROUTES from '../../constants/routes'
import UserLocationContext from '../../context/user-location-context'

function Cookie() {
  const userLocation = useContext(UserLocationContext)

  return (
    <CookieConsent
      location="bottom"
      buttonText="Ok, got it"
      className={styles.contentWrapper}
      contentStyle={{ flex: '1 0', margin: 0, padding: 0 }}
      buttonStyle={{ background: '#e8e8e8' }}
      expires={150}
    >
      <p className={styles.content}>
        We use cookies to provide you with a better service and for promotional
        purposes. By continuing to use this site you consent to our use of
        cookies as described in our{' '}
        <Link
          to={
            userLocation === USA_COUNTRY_CODE
              ? ROUTES.US_PRIVACY_POLICY
              : ROUTES.PRIVACY_POLICY
          }
        >
          privacy policy
        </Link>
      </p>
    </CookieConsent>
  )
}

export default React.memo(Cookie)
