import classNames from 'classnames'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

import * as styles from './NotificationBanner.module.scss'
import CloseButton from '../../ui/buttons/custom/close-button/CloseButton'
import GetBambooButton from '../../ui/buttons/custom/get-bamboo-button/GetBambooButton'

const IS_BANNER_CLOSED_STORAGE_NAME = 'isNotificationBannerClosed'
const isBrowser = typeof window !== 'undefined'

function NotificationBanner({
  className,
  text,
  redirectionPath,
  isDownloadAppLink,
  ...rest
}) {
  const [isClosedState, setIsClosedState] = useState(false)
  let isBannerClosed = false

  if (isBrowser) {
    isBannerClosed =
      sessionStorage?.getItem(IS_BANNER_CLOSED_STORAGE_NAME) === 'true'
  }

  const hadleCloseBanner = () => {
    setIsClosedState(true)
    sessionStorage.setItem(IS_BANNER_CLOSED_STORAGE_NAME, 'true')
  }

  return (
    <div
      className={classNames(
        styles.bannerContainer,
        (isClosedState || isBannerClosed) && styles.close,
        className
      )}
      {...rest}
    >
      <div className={styles.linkWrapper}>
        <p>{text}</p>

        {isDownloadAppLink ? (
          <GetBambooButton className={styles.getBambooBtn} />
        ) : (
          <Link className={styles.link} to={redirectionPath}>
            Go to page
          </Link>
        )}
      </div>
      <CloseButton className={styles.closeBtn} onClick={hadleCloseBanner} />
    </div>
  )
}

NotificationBanner.propTypes = {
  redirectionPath: PropTypes.string.isRequired,
  text: PropTypes.string,
  className: PropTypes.string,
  isDownloadAppLink: PropTypes.bool,
}

export default NotificationBanner
