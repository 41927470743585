import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import * as styles from './StoresBlock.module.scss'
import iOSAppStore from '../../assets/new-icons/AppBadges_Apple.svg'
import AndroidAppStore from '../../assets/new-icons/AppBadges_Google.svg'
import { useDesktopOverlayContext } from '../../context/desktop-overlay-context/DesktopOverlayContext'

function StoresBlockDesktop({ className, width = 135, height = 40 }) {
  const { toggleDownloadScreenAppeared } = useDesktopOverlayContext()
  return (
    <div
      className={classNames(styles.wrapper, styles.desktop, className)}
      onClick={toggleDownloadScreenAppeared}
    >
      <div className={styles.appDownloadLink}>
        <img
          alt="Bamboo investment iOS App"
          src={iOSAppStore}
          width={width}
          height={height}
          style={{ width, height }}
          loading="lazy"
        />
      </div>
      <div className={styles.appDownloadLink}>
        <img
          alt="Bamboo investment Android App"
          src={AndroidAppStore}
          width={width}
          height={height}
          style={{ width, height }}
          loading="lazy"
        />
      </div>
    </div>
  )
}

StoresBlockDesktop.propTypes = {
  className: PropTypes.string,
}

export default StoresBlockDesktop
