import React from 'react'

export function SvgHamburger({ width = 18, height = 12, className }) {
  return (
    <svg {...{ width, height, className }} xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 2c-.55 0-1-.45-1-1s.45-1 1-1h16c.55 0 1 .45 1 1s-.45 1-1 1H1zm16 10H1c-.55 0-1-.45-1-1s.45-1 1-1h16c.55 0 1 .45 1 1s-.45 1-1 1z"
      />
    </svg>
  )
}
