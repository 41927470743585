import classNames from 'classnames'
import { Link, navigate } from 'gatsby'
import debounce from 'lodash/debounce'
import PropTypes from 'prop-types'
import React, { useContext, useEffect, useState, useCallback } from 'react'

import * as styles from './BlogHeader.module.scss'
import ROUTES from '../../constants/routes'
import { RegionContext } from '../../context/region-context'
import Input from '../ui/input/Input'
import { SvgSearch } from '../ui/svg-icons'

const blogCategories = [
  {
    name: 'All',
    path: ROUTES.BLOG,
    regexp: /\/blog\/(?![a-z])|((\d\/)?(^$))/,
  },
  {
    name: 'Crypto',
    path: `${ROUTES.BLOG}crypto/`,
    regexp: 'crypto',
  },
  {
    name: 'Investing',
    path: `${ROUTES.BLOG}investing/`,
    regexp: 'investing',
  },
  {
    name: 'SMSF',
    path: `${ROUTES.BLOG}smsf/`,
    regexp: 'smsf',
  },
  {
    name: 'Culture',
    path: `${ROUTES.BLOG}culture/`,
    regexp: 'culture',
  },
  {
    name: 'Product',
    path: `${ROUTES.BLOG}product/`,
    regexp: 'product',
  },
]
const usBlogCategories = [
  {
    name: 'All',
    path: ROUTES.US_BLOG,
    regexp: /\/us\/blog\/(?![a-z])|((\d\/)?(^$))/,
  },
  {
    name: 'Crypto',
    path: `${ROUTES.US_BLOG}crypto/`,
    regexp: 'crypto',
  },
  {
    name: 'Investing',
    path: `${ROUTES.US_BLOG}investing/`,
    regexp: 'investing',
  },
  {
    name: 'Culture',
    path: `${ROUTES.US_BLOG}culture/`,
    regexp: 'culture',
  },
  {
    name: 'Product',
    path: `${ROUTES.US_BLOG}product/`,
    regexp: 'product',
  },
]

function BlogHeader({ location, ...rest }) {
  const region = useContext(RegionContext)
  const [searchValue, setSearchValue] = useState('')
  const categories = region.isUSRegion ? usBlogCategories : blogCategories

  // Create a debounced navigation function
  const debouncedNavigate = useCallback(
    debounce((searchQuery, currentLocation) => {
      if (searchQuery) {
        navigate(`${currentLocation}?search=${encodeURIComponent(searchQuery)}`)
      } else {
        navigate(currentLocation)
      }
    }, 500), // 300ms delay
    []
  )

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const searchQuery = params.get('search') || ''
    setSearchValue(searchQuery)
  }, [location])

  const handleSearch = event => {
    const searchQuery = event.target.value
    setSearchValue(searchQuery)
    debouncedNavigate(searchQuery, location)
  }

  // Cleanup debounce on unmount
  useEffect(() => {
    return () => {
      debouncedNavigate.cancel()
    }
  }, [debouncedNavigate])

  return (
    <div className={styles.header} {...rest}>
      <nav className={styles.navigation}>
        <ul className={styles.navList}>
          {categories.map(({ name, path, regexp }, index) => {
            const isActive = new RegExp(regexp).test(location)

            return (
              <li key={index}>
                <Link
                  className={classNames(
                    styles.navItem,
                    isActive && styles.active
                  )}
                  to={path}
                >
                  {name}
                </Link>
              </li>
            )
          })}
        </ul>
        <div className={styles.search}>
          {!region.isUSRegion && (
            <Input
              onChange={handleSearch}
              value={searchValue}
              name="search"
              type="search"
              placeholder="Search"
              className={styles.searchInput}
              icon={<SvgSearch />}
            />
          )}
        </div>
      </nav>
    </div>
  )
}

BlogHeader.propTypes = {
  location: PropTypes.string.isRequired,
}

export default BlogHeader
