import React from 'react'

export function SvgMute({ width = 41, height = 41, className }) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.75 8C11.75 6.525 10.9 5.25833 9.66667 4.64167V6.48333L11.7083 8.525C11.7333 8.35833 11.75 8.18333 11.75 8ZM13.8333 8C13.8333 8.78333 13.6667 9.51667 13.3833 10.2L14.6417 11.4583C15.1917 10.425 15.5 9.25 15.5 8C15.5 4.43333 13.0083 1.45 9.66667 0.691667V2.40833C12.075 3.125 13.8333 5.35833 13.8333 8ZM1.55833 0.5L0.5 1.55833L4.44167 5.5H0.5V10.5H3.83333L8 14.6667V9.05833L11.5417 12.6C10.9833 13.0333 10.3583 13.375 9.66667 13.5833V15.3C10.8167 15.0417 11.8583 14.5083 12.7417 13.7917L14.4417 15.5L15.5 14.4417L8 6.94167L1.55833 0.5ZM8 1.33333L6.25833 3.075L8 4.81667V1.33333Z"
        fill="#001781"
      />
    </svg>
  )
}
