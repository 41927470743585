import { getAnalytics } from 'firebase/analytics'
import { useEffect } from 'react'

import getFirebase from './initFirebase'

const BootstrapFirebase = ({ children }) => {
  useEffect(() => {
    const instance = getFirebase()

    if (instance) {
      /**
       * Initialize firebase analytics on initial render
       */
      getAnalytics(instance)
    }
  }, [])

  return children
}

export default BootstrapFirebase
