import PropTypes from 'prop-types'
import React, { useContext, useEffect } from 'react'

import ROUTES from '../../constants/routes'
import { DesktopOverlayContextProvider } from '../../context/desktop-overlay-context/DesktopOverlayContext'
import { RegionContext } from '../../context/region-context'
import WaitingListBanner from '../banners/waiting-list-banner/WaitingListBanner'
import Cookie from '../cookie/Cookie'
import Footer from '../footer/Footer'
import Header from '../header/Header'

import 'react-h5-audio-player/lib/styles.css'
import './layout.scss'

const isBrowser = typeof window !== 'undefined'

// This code block enables smooth and consistent scrolling to the top of the page when a new page is loaded.
const smoothScrollToTop = (duration = 100) => {
  const totalDuration = duration // Total duration of the scroll animation in milliseconds (100ms = super fast).
  const start = window.scrollY // Get the current vertical scroll position.
  const startTime = performance.now() // Capture the starting time of the animation.

  /**
   * Function that animates the scroll movement using requestAnimationFrame for smooth performance.
   * Where @param {number} currentTime is the current timestamp provided by requestAnimationFrame.
   */
  const animateScroll = currentTime => {
    const elapsed = currentTime - startTime // Calculate how much time has passed since the animation started.
    const progress = Math.min(elapsed / totalDuration, 1) // Normalize progress between 0 and 1 (prevents overshooting).

    // Apply an ease-in-out quadratic function for a smooth acceleration and deceleration effect.
    const easeInOut =
      progress < 0.5
        ? 2 * progress * progress // Ease-in phase (accelerating).
        : 1 - (-2 * progress + 2) ** 2 / 2 // Ease-out phase (decelerating).

    // Set the new scroll position by interpolating between the start position and 0 (top of the page).
    window.scrollTo(0, start * (1 - easeInOut))

    // If the animation is not yet complete, continue requesting animation frames.
    if (elapsed < totalDuration) {
      requestAnimationFrame(animateScroll) // Recursively call animateScroll until the duration is reached.
    }
  }

  requestAnimationFrame(animateScroll) // Start the animation loop using requestAnimationFrame for smooth performance.
}

function Layout({ children, location, pageContext }) {
  const isWaitlistPage = location?.pathname === ROUTES.WAITLIST

  const region = useContext(RegionContext)

  useEffect(() => {
    smoothScrollToTop()

    if (pageContext?.isUSRegion) {
      region?.setActiveUSRegion(true)
    } else if (isBrowser) {
      region?.setActiveUSRegion(
        JSON.parse(localStorage?.getItem('isUSRegionSelected'))
      )
    }
  }, [location.pathname])

  return (
    <DesktopOverlayContextProvider>
      <div className="content">
        {location?.pathname !== ROUTES.DOWNLOAD &&
          location?.pathname !== ROUTES.DOWNLOAD_SNAP &&
          location?.pathname !== ROUTES.DOWNLOAD_FACEBOOK &&
          location?.pathname !== ROUTES.EARLY_ACCESS && <Cookie />}
        <Header
          isBlogLayout={pageContext?.layout === 'blog'}
          location={location?.pathname}
        />
        {children}
        <Footer location={location?.pathname} />

        {!isWaitlistPage &&
          location?.pathname !== ROUTES.DOWNLOAD &&
          location?.pathname !== ROUTES.DOWNLOAD_SNAP &&
          location?.pathname !== ROUTES.DOWNLOAD_FACEBOOK &&
          location?.pathname !== ROUTES.EARLY_ACCESS && <WaitingListBanner />}
      </div>
    </DesktopOverlayContextProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
