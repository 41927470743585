import React from 'react'

export function SvgVolume({ width = 41, height = 41, className }) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.5 5.50023V10.5002H3.83333L8 14.6669V1.33356L3.83333 5.50023H0.5ZM11.75 8.00023C11.75 6.52523 10.9 5.25856 9.66667 4.64189V11.3502C10.9 10.7419 11.75 9.47523 11.75 8.00023ZM9.66667 0.691895V2.40856C12.075 3.12523 13.8333 5.35856 13.8333 8.00023C13.8333 10.6419 12.075 12.8752 9.66667 13.5919V15.3086C13.0083 14.5502 15.5 11.5669 15.5 8.00023C15.5 4.43356 13.0083 1.45023 9.66667 0.691895Z"
        fill="#001781"
      />
    </svg>
  )
}
