/* eslint-disable import/extensions */
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from 'react'

import Layout from './src/components/layout/Layout'
import RootWrap from './wrapRootElement'

import './src/assets/styles/global.scss'
import 'focus-visible'

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (window?.Intercom) {
    window.Intercom('update')
  }

  if (location?.state) {
    // eslint-disable-next-line no-param-reassign
    location.state.prevUrl = prevLocation?.pathname
      ? prevLocation.pathname
      : null
  }
}

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>
}

export const wrapRootElement = RootWrap
