import React from 'react'

export function SvgSpotify({ width = 32, height = 33, className }) {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.0002 5.78076C10.1135 5.78076 5.3335 10.5608 5.3335 16.4474C5.3335 22.3341 10.1135 27.1141 16.0002 27.1141C21.8868 27.1141 26.6668 22.3341 26.6668 16.4474C26.6668 10.5608 21.8868 5.78076 16.0002 5.78076ZM20.5068 20.5874C20.3402 20.8674 19.9668 20.9541 19.7002 20.7874C17.4668 19.4208 14.6468 19.1074 11.3335 19.8741C11.0202 19.9474 10.6935 19.7474 10.6202 19.4341C10.5535 19.1208 10.7535 18.7941 11.0668 18.7208C14.6935 17.9008 17.8068 18.2541 20.3068 19.7874C20.5935 19.9341 20.6802 20.3008 20.5068 20.5874ZM21.6735 17.9808C21.4602 18.3408 21.0068 18.4408 20.6468 18.2274C18.0868 16.6474 14.1935 16.1941 11.1668 17.1141C10.7668 17.2274 10.3535 17.0141 10.2402 16.6208C10.1268 16.2208 10.3402 15.8074 10.7402 15.6941C14.1935 14.6408 18.4868 15.1541 21.4335 16.9608C21.7735 17.1874 21.8868 17.6408 21.6735 17.9808ZM22.9802 14.9808C22.7268 15.4074 22.1868 15.5541 21.7602 15.2941C18.6868 13.4741 13.6268 13.3074 10.6935 14.2008C10.2268 14.3408 9.72683 14.0741 9.58683 13.6008C9.44016 13.1341 9.7135 12.6341 10.1802 12.4941C13.5402 11.4674 19.1402 11.6674 22.6668 13.7608C23.0935 14.0008 23.2402 14.5541 22.9802 14.9808Z"
        fill="#0047BB"
      />
    </svg>
  )
}
