import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import * as styles from './CloseButton.module.scss'
import { SvgClose } from '../../../svg-icons'

function CloseButton({ className, children, ...rest }) {
  return (
    <button
      className={classNames(styles.btn, className)}
      aria-label="Close mobile menu"
      {...rest}
    >
      {children} <SvgClose />
    </button>
  )
}

CloseButton.propTypes = {
  className: PropTypes.string,
}

export default CloseButton
