import classNames from 'classnames'
import { StaticImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'

import * as styles from './DesktopAppDownload.module.scss'
import iOSAppStore from '../../assets/new-icons/AppBadges_Apple.svg'
import AndroidAppStore from '../../assets/new-icons/AppBadges_Google.svg'
import StoresBlock from '../storesBlock/StoresBlock'
import CloseButton from '../ui/buttons/custom/close-button/CloseButton'

function DesktopAppDownload({ onCloseClick, className, ...rest }) {
  useEffect(() => {
    const ESCAPE_KEY = 27

    const handleKeyDown = event => {
      if (event.keyCode === ESCAPE_KEY) {
        onCloseClick()
      }
    }

    document.body.addEventListener('keydown', handleKeyDown)
    document.body.classList.add('none-scrollable')

    return () => {
      document.body.removeEventListener('keydown', handleKeyDown)
      document.body.classList.remove('none-scrollable')
    }
  }, [])

  return (
    <div className={classNames(styles.appDownloadScreen, className)} {...rest}>
      <section className={styles.section}>
        <h2 className={styles.title}>
          Download from your favourite app store to Get Bamboo.
        </h2>
        <div className={styles.desktop}>
          <div className={styles.qrcode}>
            <StaticImage
              src="../../assets/images/download-qr.png"
              alt="Bamboo app download qr code"
              width={200}
              quality={100}
            />
          </div>
          <div className={styles.downloadLinks}>
            <img
              alt="Bamboo investment iOS App"
              src={iOSAppStore}
              width="135"
              height="40"
              loading="lazy"
            />
            <img
              alt="Bamboo investment Android App"
              src={AndroidAppStore}
              width="135"
              height="40"
              loading="lazy"
            />
          </div>
        </div>
        <div className={styles.mobile}>
          <StoresBlock onDownloadButtonClick={onCloseClick} />
        </div>
        <CloseButton className={styles.close} onClick={onCloseClick}>
          I'll do this later
        </CloseButton>
      </section>
    </div>
  )
}

DesktopAppDownload.propTypes = {
  onCloseClick: PropTypes.func.isRequired,
  className: PropTypes.string,
}

export default DesktopAppDownload
