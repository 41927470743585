import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

import ROUTES from './routes'
import {
  BAMBOO_FACEBOOK_URL,
  BAMBOO_INSTAGRAM_URL,
  BAMBOO_SPOTIFY_URL,
  BAMBOO_TWITTER_URL,
  BAMBOO_YOUTUBE_URL,
  BLOCKCHAIN_AUS_URL,
  FINTECH_AUS_URL,
} from './seo'
import {
  SvgFacebook,
  SvgInstagram,
  SvgSpotify,
  SvgTwitter,
  SvgYouTube,
} from '../components/ui/svg-icons'

const FeaturesOptions = [
  {
    url: ROUTES.ROUND_UPS,
    us_url: ROUTES.US_ROUND_UPS,
    label: 'Round-ups',
    descriptionTitle: 'Bamboo - Put your spare change to work',
    isExternal: false,
  },
  {
    url: ROUTES.TOP_UPS,
    us_url: ROUTES.US_TOP_UPS,
    label: 'Top-ups',
    descriptionTitle: 'Bamboo - Instant top-ups',
    isExternal: false,
  },
  {
    url: ROUTES.PORTFOLIO,
    us_url: ROUTES.US_PORTFOLIO,
    label: 'Portfolio',
    descriptionTitle: 'Bamboo - Pick investment portfolio or make it your own',
    isExternal: false,
  },
  {
    url: ROUTES.DOLLAR_COST_AVERAGE,
    us_url: ROUTES.US_DOLLAR_COST_AVERAGE,
    label: 'Dollar Cost Averaging',
    descriptionTitle: 'Bamboo - Dollar Cost Averaging',
    isExternal: false,
  },
  {
    url: ROUTES.BAM_REWARDS,
    label: 'BAM Rewards',
    descriptionTitle: 'Bamboo - Earn rewards while using the app',
    isExternal: false,
    disabledOnUS: true,
  },
  {
    url: ROUTES.REBALANCE,
    label: 'Rebalance',
    descriptionTitle: 'Bamboo - Automated or manual investments rebalance',
    isExternal: false,
  },
  {
    url: ROUTES.SMSF,
    label: 'SMSF',
    descriptionTitle: 'Bamboo - SMSF',
    isExternal: false,
  },
]

const CompanyOptions = [
  {
    url: ROUTES.FEATURES,
    us_url: ROUTES.US_FEATURES,
    label: 'Features',
    descriptionTitle: 'Bamboo - Features',
    isExternal: false,
  },
  {
    url: ROUTES.ABOUT,
    us_url: ROUTES.US_ABOUT,
    label: 'About',
    descriptionTitle: 'Bamboo - Get to know us',
    isExternal: false,
  },
  {
    url: ROUTES.TEAM,
    label: 'Team',
    descriptionTitle: 'Bamboo - Team',
    isExternal: false,
  },
  {
    url: ROUTES.FEES,
    label: 'Fees',
    descriptionTitle: 'Bamboo - Fees',
    isExternal: false,
  },
  {
    url: ROUTES.SECURITY,
    us_url: ROUTES.US_SECURITY,
    label: 'Security',
    descriptionTitle: 'Bamboo - Security',
    isExternal: false,
  },
  {
    url: ROUTES.WAITLIST,
    label: 'Waitlist',
    descriptionTitle: 'Bamboo - Waitlist',
    isExternal: false,
    disabledOnUS: true,
  },
]

const SupportOptions = [
  {
    url: ROUTES.SUPPORT,
    us_url: ROUTES.US_SUPPORT,
    label: 'Contact',
    descriptionTitle: 'Bamboo - Contact us',
    isExternal: false,
  },
  {
    url: 'https://www.linkedin.com/company/getbambooapp/',
    label: 'Careers',
    descriptionTitle: 'Bamboo - Work with us',
    isExternal: true,
  },
  {
    url: ROUTES.FAQ,
    us_url: ROUTES.US_FAQ,
    label: 'FAQ',
    descriptionTitle: 'Bamboo - Frequently Asked Questions',
    isExternal: false,
  },
]

const UsefulLinksOptions = [
  {
    url: ROUTES.PARTNERS,
    us_url: ROUTES.US_PARTNERS,
    label: 'Affiliates',
    descriptionTitle: 'Bamboo - Partners',
    isExternal: false,
  },
  // {
  //   url: ROUTES.REFERRAL,
  //   us_url: ROUTES.US_REFERRAL,
  //   label: 'Referral',
  //   descriptionTitle: 'Bamboo - Referral',
  //   isExternal: false,
  // },
  {
    url: ROUTES.BLOG,
    us_url: ROUTES.US_BLOG,
    label: 'Blog',
    descriptionTitle: 'Bamboo - Blog',
    isExternal: false,
  },
  {
    url: ROUTES.CRYPTO_CURIOUS,
    us_url: ROUTES.US_CRYPTO_CURIOUS,
    label: 'Podcast',
    descriptionTitle: 'Bamboo - Crypto Curious podcast',
    isExternal: false,
  },
  {
    url: ROUTES.COMMUNITY,
    us_url: ROUTES.US_COMMUNITY,
    label: 'Community',
    descriptionTitle: 'Bamboo - Community',
    isExternal: true,
  },
]

export const legalOptions = [
  {
    url: ROUTES.TERMS,
    us_url: ROUTES.US_TERMS,
    label: 'Terms',
    descriptionTitle: 'Bamboo - Terms and Conditions',
    isExternal: false,
  },
  {
    url: ROUTES.PROMOTIONS,
    us_url: ROUTES.US_PROMOTIONS,
    label: 'Promotions',
    descriptionTitle: 'Bamboo - Promotions',
    isExternal: false,
  },
  {
    url: ROUTES.PRIVACY_POLICY,
    us_url: ROUTES.US_PRIVACY_POLICY,
    label: 'Privacy Policy',
    descriptionTitle: 'Bamboo - Privacy Policy',
    isExternal: false,
  },
  {
    url: ROUTES.PRIVACY_COLLECTION_STATEMENT,
    us_url: ROUTES.US_PRIVACY_COLLECTION_STATEMENT,
    label: 'Privacy Statement',
    descriptionTitle: 'Bamboo - Privacy Statement',
    isExternal: false,
  },
  // {
  //   url: ROUTES.EVOLVE_AGREEMENT,
  //   label: 'Evolve Agreement',
  //   descriptionTitle: 'Bamboo - Evolve Agreement',
  //   isExternal: false,
  // },
  {
    url: ROUTES.RISK_DISCLOSURE_STATEMENT,
    label: 'Disclosure & Consent',
    descriptionTitle: 'Bamboo - Disclosure & Consent',
    isExternal: false,
    disabledOnUS: true,
  },
  {
    us_url: ROUTES.US_ADDITIONAL_REQUIRED_DISCLOSURES,
    label: 'Additional Required Disclosures',
    descriptionTitle: 'Bamboo - Additional Required Disclosures',
    isExternal: false,
    disabledOnAU: true,
  },
]

export const navLinks = [
  {
    url: ROUTES.HOME,
    us_url: ROUTES.US_HOME,
    label: 'Home',
    descriptionTitle: 'Invest today’s change in tomorrow',
    isExternal: false,
  },
  {
    url: ROUTES.FEATURES,
    us_url: ROUTES.US_FEATURES,
    label: 'Features',
    descriptionTitle: 'Bamboo -  Features',
    isExternal: false,
    subMenus: [...FeaturesOptions],
  },
  {
    url: ROUTES.FEES,
    label: 'Fees',
    descriptionTitle: 'Bamboo - Fees',
    isExternal: false,
    disabledOnUS: true,
  },
  {
    url: ROUTES.SECURITY,
    us_url: ROUTES.US_SECURITY,
    label: 'Security',
    descriptionTitle: 'Bamboo security process',
    isExternal: false,
  },
  {
    url: ROUTES.BLOG,
    us_url: ROUTES.US_BLOG,
    label: 'Blog',
    descriptionTitle: 'Bamboo - Blog',
    isExternal: false,
  },
  // {
  //   url: ROUTES.ABOUT,
  //   us_url: ROUTES.US_ABOUT,
  //   label: 'About Us',
  //   descriptionTitle: 'Bamboo - About Us',
  //   isExternal: false,
  // },
  {
    url: ROUTES.FAQ,
    us_url: ROUTES.US_FAQ,
    label: 'FAQ',
    descriptionTitle: 'Bamboo - help and FAQ',
    isExternal: false,
  },
  {
    url: ROUTES.SUPPORT,
    us_url: ROUTES.US_SUPPORT,
    label: 'Support',
    descriptionTitle: 'Bamboo - Support',
    isExternal: false,
  },
]

export const SocialOptions = [
  {
    label: 'Instagram',
    url: BAMBOO_INSTAGRAM_URL,
    icon: SvgInstagram,
    description: 'Bamboo investment App - Instagram',
    isExternal: true,
  },
  {
    label: 'Facebook',
    url: BAMBOO_FACEBOOK_URL,
    icon: SvgFacebook,
    description: 'Bamboo investment App - Facebook',
    isExternal: true,
  },
  {
    label: 'Twitter',
    url: BAMBOO_TWITTER_URL,
    icon: SvgTwitter,
    description: 'Bamboo investment App - Twitter',
    isExternal: true,
  },
  {
    label: 'YouTube',
    url: BAMBOO_YOUTUBE_URL,
    icon: SvgYouTube,
    description: 'Bamboo investment App - YouTube',
    isExternal: true,
  },
  // {
  //   label: 'LinkedIn',
  //   url: BAMBOO_LINKEDIN_URL,
  //   icon: SvgLinkedin,
  //   description: 'Bamboo investment App - LinkedIn',
  //   isExternal: true,
  // },
  {
    label: 'Spotify',
    url: BAMBOO_SPOTIFY_URL,
    icon: SvgSpotify,
    description: 'Bamboo investment App - Spotify',
    isExternal: true,
  },
]

export const MobileMenuSocialLinks = [
  {
    title: 'Facebook',
    path: BAMBOO_FACEBOOK_URL,
    icon: SvgFacebook,
    description: 'Bamboo investment App - Facebook',
  },
  {
    title: 'Instagram',
    path: BAMBOO_INSTAGRAM_URL,
    icon: SvgInstagram,
    description: 'Bamboo investment App - Instagram',
  },
  {
    title: 'Twitter',
    path: BAMBOO_TWITTER_URL,
    icon: SvgTwitter,
    description: 'Bamboo investment App - Twitter',
  },
]

export const CertificationNavLinks = isUSRegion => [
  {
    image: (
      <StaticImage
        src="../assets/new-icons/AppBadges_CryptoCurious.png"
        alt="Bamboo CryptoCurios Podcast logo"
        height={40}
        quality={100}
      />
    ),
    title: 'Crypto Curious',
    url: isUSRegion ? ROUTES.US_CRYPTO_CURIOUS : ROUTES.CRYPTO_CURIOUS,
  },
  {
    image: (
      <StaticImage
        src="../assets/images/partners-logo/Blockchain-Australia-Certification.png"
        alt="Bamboo partner Blockchain Australia Stacked logo"
        height={54}
        quality={100}
      />
    ),
    title: 'Blockchain Australia',
    url: BLOCKCHAIN_AUS_URL,
  },
  {
    image: (
      <StaticImage
        src="../assets/images/partners-logo/Fintech-Australia.png"
        alt="Fintech Australia logo"
        height={18}
        quality={100}
      />
    ),
    title: 'Fintech Australia',
    url: FINTECH_AUS_URL,
  },
  {
    /* TODO(SOC2): Uncomment to add SOC2 Logo */
  },
  // {
  //   image: (<StaticImage
  //     src='../assets/images/partners-logo/SOC2-Compliant-Certification.png'
  //     alt="SOC2 Compliant logo"
  //     height={40}
  //     quality={100}
  //   />),
  //   title: 'SOC2 Compliant',
  // }
]

export const FooterSiteNavLinks = [
  {
    title: 'Company',
    links: [...CompanyOptions],
  },
  {
    title: 'Features',
    links: [...FeaturesOptions],
  },
  {
    title: 'Support',
    links: [...SupportOptions],
  },
  {
    title: 'Useful links',
    links: [...UsefulLinksOptions],
  },
]
