import React from 'react'

export function SvgFacebook({ width = 32, height = 33, alt, className }) {
  return (
    <svg
      width={width}
      height={height}
      alt={alt}
      className={className}
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.9632 9.40219C21.9632 9.40219 20.5744 9.40219 19.5489 9.40219C18.502 9.40219 17.9037 9.98974 17.9037 11.2289V13.6219H21.7068L21.162 17.3608H17.9037C17.9037 21.8475 17.9037 27.1141 17.9037 27.1141H13.9832C13.9832 27.1141 13.9832 22.0291 13.9832 17.3608H10.7036V13.6219H13.9832V10.7482C13.9832 7.052 16.5577 5.78076 18.5447 5.78076C20.5317 5.78076 21.9632 5.78076 21.9632 5.78076V9.40219Z"
        fill="#0047BB"
      />
    </svg>
  )
}
